img.headshot {
    vertical-align:middle; 
    text-align:center;
    position: relative;
    width: 100%;
}

div.bioPanel {
    padding: 15px;
    background-color: rgb(216, 198, 191);
}