div.MusicPlayer-Wrapper{
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 90%;
}

div.listDiv {
    display: flex;
    flex-direction: column;
    
}

div.track-li-elements{
    display: flex;
    flex-direction: row;
}

div.track-li-elements *{
    color: ivory;
}

ol.ordered-list-object {
    list-style-type: none;
    padding-left: 0%;
}

div.track-button-container{
    display: flex;
    height: 2em;
    align-items: center;
    border-bottom: solid;
    border-width: 2px;
    padding-left: 2%;

}
div.track-button-container:hover{
    background-color: rgb(75, 74, 74);
    display: flex;
    height: 2em;
    align-items: center;
    border-bottom: solid;
    border-width: 2px;
    cursor: pointer;
}

div.TitleAndControls button
{
    appearance: none;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    fill: white;
}
div.TitleAndControls button:hover
{
    cursor: pointer;
}
div.TitleAndControls button:focus
{
    outline: none;
}

div.TitleAndControls{

    width: 90%;
}

div.player{
    display: flex; 
    margin: auto; 
    flex-direction: row;
}

div.album-actions-title {
    margin-top: 5%;
    color: ivory;
}

div.title * {
    margin: 0px;
}

div.Controls 
{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: ivory;
}

div.kkSlider{
    height: 8px;
    overflow: hidden;
    width: 85%;
    flex-shrink: inherit;
    background-color: ivory;
    margin-right: 15px;
}

div.kkCrusin {
    height: 8px;
    flex-shrink: inherit;
    background-color: rgb(114, 114, 114);
    margin-right: 15px;
}