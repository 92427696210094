div.root{
  background-color: rgb(36, 36, 36);
}

body {
  background-color: rgb(17, 17, 17);
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
