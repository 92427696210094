/* Style the header */
.Site-Header {
  color: rgb(31, 31, 31);
  background-color: rgb(44, 44, 44);
	position: sticky;
	z-index: 100;	
  text-align: center;
  top: 0;
  width: 100%
}

.Site-Header button.header-btn {
  color: rgb(196, 196, 196);
  background-color: rgb(32, 32, 32);
  margin: 0px;
  padding: 0px;
  height: 45px;
  width: 150px;
  border: none; 
}

.Site-Header button.header-btn:hover {
  color: rgb(247, 247, 247);
  background-color: rgb(85, 85, 85);
}
.Site-Header button.header-btn:focus{
  outline: none;
}